import { getRequest } from '@/api/axios'

// 获取栏目id
export const getColumnID = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/list', params)
}

export const getColumnList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}
